<template>
	<div>
		<HeaderPage/>
	<main>
	<section class="breadcrumbs">
      <div class="container">

        <ol>
          <li><a href="/">Home</a></li>
          <li>Origin</li>
        </ol>

      </div>
    </section>

    <!-- ======= Blog Section ======= -->
    <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">
        <div class="row">
              <div class="col-10">
                <article class="entry">
                  <div class="entry-content">
                      <p>Established on the recognition of the four needs of a human being - to live, to love, to learn and to leave a legacy- emerged Vilsons; founded by Late Shri J Ponnusamy Villavarayar, a visionary, a dominant force in the Gulf of Mannar, a legend of his time and a firm believer that enduring success never comes easily to an Individual or to an Organization.</p>
                    <p>Since its inception in 1944, Vilsons, whose roots date back to early 1920s, when the erstwhile minor port was dominated by Shri Rao Bahadur Caitan Villavarayar, operating lightening boats for handling vessels which belonged to the then leaders M/s British India Steam Navigation, P&amp; O, etc., occupies prime place in the field of Maritime Activities in the port of Tuticorin.</p>
                    <p>Shri J P Joe Villavarayar inherited the Vilsons empire along with his father’s volcanic passion to create landmarks and leave a legacy. He firmly believes that the quality of a person’s life is in direct proportion to his commitment to excellence, regardless of the chosen field of endeavour. Like his father, he dared to dream, a dream for
                    Vilsons - ‘to be a Leader, if not the Leader.’</p>
                    <p>Today, under the committed leadership of Shri J P Joe Villavarayar, Vilsons is striving to leave an imprint in history as a group of dynamic men, who understood what is needed to be done, had the strength, the self-discipline to achieve the goals with excellence and the resolve to see the future as well.</p>
                  </div>
              </article>
              </div>
              <div class="col-2">
                <div class="entry-img">
                    <img src="assets/img/ponnusamyvillavarayar.png" alt="" class="img-fluid">
                </div>
              </div>
            </div>
    </div>
    </section>

  </main>
	</div>
</template>


<script>
import HeaderPage from '@/components/Header.vue';
export default {
  name: 'Originpage',
  components: {
    HeaderPage,
  },
}
</script>